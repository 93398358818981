@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,700);
html, body, #root {
  font-family: 'Open Sans', sans-serif;
}

/* @media (min-width: 1025px) {
  html, body, #root {
    display: none;
  }
} */

/* const width */
.w-unset { width: unset !important; }
.w-50 { width: 50%; }
.w-50-1 { width: calc(50% - 1px); }
.w-100 { width: 100%; }
.w-110 { width: 110px !important; }
.w-100-40 { width: calc(100% - 40px) !important; }

/* const background */
.bc-dark { background-color: #1f1f1f; }

/* const border */
.b-1-solid { border: 1px solid; }
.bt-1-solid { border-top: 1px solid; }
.br-1-solid { border-right: 1px solid; }
.bb-1-solid { border-bottom: 1px solid; }
.bl-1-solid { border-left: 1px solid; }
.bb-unset { border-bottom: unset !important; }
.br-2 { border-radius: 2rem !important; }
.btrr-2 { border-top-right-radius: 2rem !important; }
.bbrr-2 { border-bottom-right-radius: 2rem !important; }
.btlr-2 { border-top-left-radius: 2rem !important; }
.bblr-2 { border-bottom-left-radius: 2rem !important; }

/* const display */
.d-flex { display: flex; }
.d-inline-flex { display: inline-flex; }

/* const position */
.p-relative { position: relative; }
.p-absolute { position: absolute !important; }
.r-0 { right: 0; }
.r-8 { right: 8px; }
.r-10 { right: 10px; }
.r-20 { right: 20px; }
.l-8 { left: 8px; }
.l-10 { left: 10px; }

/* const cursor */
.pointer { cursor: pointer; }

/* const text-align */
.ta-center { text-align: center; }

/* const font */
.fs-10 { font-size: 10px; }
.fs-11 { font-size: 11px; }
.fs-12 { font-size: 12px; }
.fs-13 { font-size: 13px !important; }
.fs-15 { font-size: 15px; }
.fs-16 { font-size: 16px; }
.bold { font-weight: bold; }
.c-lightgray { color: rgba(0, 0, 0, 0.25) !important;}
.c-gray { color: #687374; }
.c-darkgray { color: #595959; }
.c-green { color: #00af89 !important; }
.c-black { color: #000000; }
.c-blue { color: #00b9f2 !important; }
.hover-blue:hover { color: #40a9ff; }
.ws-p { white-space: pre; }
.ws-pl { white-space: pre-line; }
.wb-ka { word-break: keep-all; }
.underline { text-decoration: underline; }

/* const padding */
.pt-10 { padding-top: 10px; }
.pt-20 { padding-top: 20px; }
.pt-30 { padding-top: 30px; }
.pt-45 { padding-top: 45px; }
.pl-10 { padding-left: 10px !important; }

/* const margin */
.m-auto { margin: auto; }
.m-0 { margin: 0px; }
.m-10 { margin: 10px; }
.mt-0 { margin-top: 0px; }
.mt-3 { margin-top: 3px; }
.mt-8 { margin-top: 8px; }
.mt-10 { margin-top: 10px !important; }
.mt-15 { margin-top: 15px !important; }
.mt-20 { margin-top: 20px !important; }
.mt-30 { margin-top: 30px !important; }
.mt-38 { margin-top: 38px !important; }
.mt-45 { margin-top: 45px; }
.mb-0 { margin-bottom: 0 !important; }
.mb-3 { margin-bottom: 3px; }
.mb-8 { margin-bottom: 8px; }
.mb-10 { margin-bottom: 10px !important; }
.mb-15 { margin-bottom: 15px; }
.mb-25 { margin-bottom: 25px; }
.mb-30 { margin-bottom: 30px !important; }
.mb-38 { margin-bottom: 38px !important; }
.mb-50 { margin-bottom: 50px !important; }
.mr-10 { margin-right: 10px; }
.mr-5 { margin-right: 5px; }
.mr-15 { margin-right: 15px; }
.mr-30 { margin-right: 30px; }
.mr-35 { margin-right: 35px; }
.ml-3 { margin-left: 3px; }
.ml-5 { margin-left: 5px; }
.ml-14 { margin-left: 14px; }
.ml-15 { margin-left: 15px; }
.ml-20 { margin-left: 20px; }
.ml-25 { margin-left: 25px; }
.ml-30 { margin-left: 30px; }
.ml-35 { margin-left: 35px; }
.ml-42 { margin-left: 42px; }

/* animation */
.wave span {
  position: relative;
  display: inline-block;
  color: #ff7a45;
  -webkit-animation: wave .9s 7;
          animation: wave .9s 7;
}
.wave span:nth-child(2) {
  -webkit-animation-delay: .1s;
          animation-delay: .1s;
}
.wave span:nth-child(3) {
  -webkit-animation-delay: .2s;
          animation-delay: .2s;
}
.wave span:nth-child(4) {
  -webkit-animation-delay: .3s;
          animation-delay: .3s;
}
.wave span:nth-child(5) {
  -webkit-animation-delay: .4s;
          animation-delay: .4s;
}
.wave span:nth-child(6) {
  -webkit-animation-delay: .5s;
          animation-delay: .5s;
}
@-webkit-keyframes wave {
  0% {top:0;}
  20% {top:-0.3rem;}
  40% {top:0}
  60% {top:0}
  80% {top:0}
  100% {top:0}
}
@keyframes wave {
  0% {top:0;}
  20% {top:-0.3rem;}
  40% {top:0}
  60% {top:0}
  80% {top:0}
  100% {top:0}
}

/* button */
.basic-button {
  display: block !important;
  width: 100%;
  font-weight: bold !important;
  color: #fff !important;
}
  .basic-button.w-0 {
    width: unset;
  }
  .basic-button.gray,
  .basic-button.gray:focus {
    background-color: #c6c6c6;
    border-color: #c6c6c6;
  }
    .basic-button.gray:hover {
      background-color: #d4d4d4;
      border-color: #d4d4d4;
    }
  .basic-button.green,
  .basic-button.green:focus {
    background-color: #00af89;
    border-color: #00af89;
  }
    .basic-button.green:hover,
    .basic-button.green:focus {
      background-color: #1ebd95;
      border-color: #1ebd95;
    }
  .basic-button.blue,
  .basic-button.blue:focus {
    background-color: #00b9f2;
    border-color: #00b9f2;
  }
    .basic-button.blue:hover {
      background-color: #29d4ff;
      border-color: #29d4ff;
    }
.fix-refresh-button {
  padding: 0 10px !important;
  background-color: #434343 !important;
  border-radius: 2rem !important;
  border-color: #434343 !important;
  font-size: 18px !important;
}

/* alert */
.alert-success .ant-message-notice-content {
  background-color: #f6ffed;
  border: 1px solid #b7eb8f;
}
.alert-fail .ant-message-notice-content {
  background-color: #fff2f0;
  border: 1px solid #ffccc7;
}

/* modal */
.modal-location-image svg {
  width: 80px !important;
  height: 80px;
  color: #00b9f2;
}

/* step */
.ant-steps-item-finish.step-wrap .ant-steps-item-content svg,
.ant-steps-item-process.step-wrap .ant-steps-item-content svg {
  color: #025070;
}
.ant-steps-item-finish.step-wrap .ant-steps-item-icon {
  border-color: #025070;
}
.ant-steps-item-finish.step-wrap .ant-steps-item-icon > .ant-steps-icon {
  color: #025070;
}
.ant-steps-item-finish.step-wrap > .ant-steps-item-container > .ant-steps-item-tail::after {
  background-color: #025070;
}
.ant-steps-item-process.step-wrap .ant-steps-item-icon {
  background: #025070;
  border-color: #025070;
}
.step-wrap .ant-steps-item-tail {
  margin-left: 18px !important;
  padding: 3.5px 18px !important;
}
.step-wrap .ant-steps-item-icon {
  margin-left: 6px !important;
}
.step-wrap .ant-steps-item-content {
  width: 37px !important;
  margin-top: 0 !important;
  text-align: center !important;
}

/* tooltip */
.ant-tooltip {
  z-index: 0 !important;
}
  .ant-tooltip .basic-button {
    font-size: 13px !important;
  }

/* notification */
.ant-notification {
  /* position: absolute !important; */
  z-index: 0 !important;
}

/* input box */
.basic-input input {
  font-size: 16px;
}
.ant-row.ant-form-item {
  margin-bottom: 18px;
}
  .ant-row.ant-form-item.ant-form-item-has-success {
    margin-bottom: 18px;
  }
  .ant-row.ant-form-item.ant-form-item-with-help {
    margin-bottom: 0 !important;
  }
  .ant-row.ant-form-item .ant-form-item-explain {
    min-height: 18px !important;
    font-size: 12px !important
  }
  
  /* form card style */
.form-card-wrap {
  background-color: #f4f4f4;
  margin-bottom: 10px;
  border: 1px solid #cdcdcd;
}
.card {
  padding: 20px 0 5px !important;
}
  .card .ant-row.ant-form-item {
    margin: 0 20px;
  }
  .card .ant-row.ant-form-item.ant-form-item-with-help .ant-form-item-explain {
    text-align: center;
  }
  .card .ant-row.ant-form-item.ant-form-item-with-help.inline-flex .ant-form-item-control-input-content {
    display: inline-flex;
  }
  .card .ant-row.ant-form-item.ant-form-item-with-help.inline-flex input {
    font-size: 14px;
  }
  .card .ant-row.ant-form-item .ant-col.ant-form-item-label label {
    color: #8a8a8a;
    font-weight: bold;
  }
    .card .ant-row.ant-form-item .ant-col.ant-form-item-label .anticon.anticon-question-circle.ant-form-item-tooltip {
      margin-left: 2px;
    }

/* form item */
.ant-form .ant-form-item .ant-form-item-label {
  flex: unset !important;
  padding: 0;
  text-align: right;
  white-space: nowrap;
}
.ant-form .ant-form-item .ant-form-item-control {
  flex: unset !important;
}
.ant-form .ant-form-item.questionnaire {
  margin-bottom: 0;
  border-bottom: 1px dashed;
}
  .ant-form .ant-form-item.questionnaire .ant-form-item-control {
    margin: 5px;
  }

/* form inline(label and input) item */
.inline-label-input {
  margin-top: 20px;
}
  .inline-label-input label {
    margin-top: 3px !important;
  }
  .inline-label-input .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: none;
  }
  .inline-label-input .inline-item .ant-form-item-control {
    margin-left: 20px;
    width: calc(100% - 70px);
  }
  @media (min-width: 575px) {
    .inline-label-input .inline-item .ant-form-item-control {
      margin-left: 20px;
      width: calc(100% - 80px);
    }
  }
  .inline-label-input .inline-item .ant-form-item-control .ant-form-item-explain.ant-form-item-explain-success {
    color: #40a9ff;
  }

/* form list */
.form-list-item {
  display: flex;
}
  .form-list-item > span {
    height: 32px;
    line-height: 35px;
  }

/* casacader */
.ant-cascader-menus {
  min-height: 250px;
  z-index: 1000;
}
  .ant-cascader-menus ul {
    min-height: 250px;
  }
  @media (min-height: 650px) {
    .ant-cascader-menus ul {
      min-height: 330px;
    }
  }
  @media (min-height: 760px) {
    .ant-cascader-menus ul {
      min-height: 430px;
    }
  }

/* no list data */
.no-list {
  text-align: center;
  margin: 50px 0 30px;
}
  .no-list svg {
    width: 80px !important;
    height: 80px;
    color: #6a6a6a;
    /* color: #f7797b; */
  }

/* layout */
.layout-wrapper {
  height: 100vh !important;
  background-color: #fff !important;
}

/* header */
.header-wrapper {
  display: inline-flex;
  position: fixed;
  width: 100%;
  background-color: #fff !important;
  border-top: 1px solid #d9d9d9;
  border-bottom: 1px solid #d9d9d9;
  padding: 0 20px !important;
  z-index: 1;
}
  .header-wrapper .header-logo {
    width: 32px;
    height: 32px;
    margin: 16px 16px 16px 0;
  }
  .header-wrapper .header-title {
    margin-bottom: 0;
    font-weight: bold;
  }
  .header-wrapper .menu-bar {
    position: absolute;
    right: 20px;
    padding-top: 2px;
    height: 62px;
    cursor: pointer;
  }
    .header-wrapper .menu-bar svg {
      color: #919191;
      width: 20px;
      height: 20px;
    }

/* content */
.content-wrapper {
  background: #fff;
}
  .content-wrapper .content-body {
    min-height: 600px;
    padding: 64px 20px 42px;
    /* z-index: 0 !important; */
  }
  .content-wrapper .content-body .content-main-logo {
    width: 150px;
    height: 150px;
  }

/* footer */
.footer-wrapper {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 26px;
  background-color: #fff !important;
  padding: 5px 50px !important;
  text-align: center;
  font-size: 12px !important;
  z-index: 1;
}
